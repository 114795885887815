import React from 'react'
import {Link} from 'gatsby'

import Layout from '../../components/layout'
import Image from '../../components/image'
import SEO from '../../components/seo'
import Title from '../../components/title';

import {withStyles} from '@material-ui/core/styles';

import img1 from '../../images/cennik/przeprowadzka/1.png'

const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px", textAlign: "justify"},
  li: {margin: "auto"},
  centered: {margin: "0 auto"},
  greyHeader: {color: "#888", margin: "1rem 0"}
};

const Paczki = () => (
  <Layout>
    <SEO title="Przeprowadzki"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <Title>PRZEPROWADZKI ANGLIA – POLSKA I POLSKA – ANGLIA</Title>
    <div className="container" css={styles.text}>
      <div css={[styles.header, styles.centered]}>Przeprowadzka z UK-TRANS Polska – Anglia, Anglia – Polska</div>
      <img src={img1} alt="przeprowadzki Anglia-Polska i Polska - Anglia"/>
      <div css={styles.header}>
          <p>Wszystkie przedmioty zlecane do przewozu są transportowane bezpośrednio z miejsca
          odbioru do miejsca docelowego.</p>

          <p>Za cel obieramy sobie zadowolenie naszego klienta łącząc przy tym wysoką jakość
          oferowanych usług, solidność i uczciwość.</p>

          <p>Ceny zależne są od lokalizacji wyjściowej i docelowej a także łącznej wielkości ładunku.</p>

          <p>W celu wyceny zlecenia i uzyskania informacji co do możliwej daty odbioru
              prosimy o skontaktowanie się z nami poprzez zakładkę KONTAKT bądź telefonicznie.</p>
      </div>
    </div>
  </Layout>
);

export default withStyles(styles)(Paczki);